(function ($) {
	$(document).ready(function () {
		// trigger animate classes
		$(".to-animate").each(function () {
			$(this).viewportChecker({
				classToAdd: "visible animated",
				offset: 100,
				callbackFunction: function (elem, action) {},
			});
		});

		// play videos in lightbox
		$("a.fancybox.video").fancybox({
			width: 1280,
			height: 720,
			autoSize: false,
			autoPlay: true, //Chrome prevents autoplay
		});

		// accordion header class toggle
		$(".bsd-accordion .collapse.show").each(function () {
			addAccordionClasses($(this));
		});
		$(".bsd-accordion .card-header").click(function () {
			addAccordionClasses($(this));
		});
		function addAccordionClasses(element) {
			element
				.closest(".bsd-accordion")
				.find(".card")
				.not(element.closest(".card"))
				.removeClass("active");
			if (element.closest(".card").hasClass("active")) {
				element.closest(".card").removeClass("active");
			} else {
				element.closest(".card").addClass("active");
			}
		}

		$(".layout-gallery-slider").each(function () {
			var slider = $(this).find(".slider-wrapper");
			var sliderNav = $(this).find(".slider-nav-wrapper");
			slider.slick({
				fade: false,
				autoplay: false,
				infinite: false,
				speed: 700,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: false,
				slide: ".slide",
				rows: 0,
				mobileFirst: true,
				asNavFor: sliderNav,
			});
			sliderNav.slick({
				fade: false,
				autoplay: false,
				infinite: true,
				speed: 700,
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: true,
				prevArrow: sliderNav.next().find(".arrow-left"),
				nextArrow: sliderNav.next().find(".arrow-right"),
				dots: false,
				slide: ".slide",
				rows: 0,
				mobileFirst: true,
				asNavFor: slider,
				focusOnSelect: true,
				centerMode: true,
				responsive: [
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 4,
						},
					},
					{
						breakpoint: 1199,
						settings: {
							slidesToShow: 5,
						},
					},
				],
			});
		});

		// Nice Select init
		$("select.custom").niceSelect();

		$(".filters.condition-categories select").on("change", function () {
			var taxonomy_slug = $(".filters.condition-categories")
				.find(".nice-select ul.list li.selected")
				.data("value");
			var taxonomy_grid = $("#grid-condition .grid-wrapper");

			taxonomy_grid.find(".square-link").hide(); // reset all tiles
			taxonomy_grid.find(".square-link").each(function () {
				if ($(this).data("category").indexOf(taxonomy_slug) >= 0) {
					$(this).show();
				}
			});

			if (taxonomy_slug !== "all") {
				$("#grid-condition .reset").show();
			}
		});
		$(".filters.condition-categories .reset").click(function () {
			var taxonomy_text = $(".filters.condition-categories")
				.find("select.custom option:first-child")
				.text();
			$(".filters.condition-categories")
				.find(".nice-select span.current")
				.text(taxonomy_text);
			$(".filters.condition-categories")
				.find(".nice-select ul.list li.option")
				.removeClass("selected");
			$(".filters.condition-categories")
				.find(".nice-select ul.list li:first-child")
				.addClass("selected");
			$("#grid-condition .grid-wrapper").find(".square-link").show();
			$(this).hide();
		});

		$(".filters.technology-categories select").on("change", function () {
			var taxonomy_slug = $(".filters.technology-categories")
				.find(".nice-select ul.list li.selected")
				.data("value");
			var taxonomy_grid = $("#grid-technology .grid-wrapper");

			taxonomy_grid.find("article").hide(); // reset all tiles
			taxonomy_grid.find("article").each(function () {
				if ($(this).data("category").indexOf(taxonomy_slug) >= 0) {
					$(this).show();
				}
			});

			if (taxonomy_slug !== "all") {
				$("#grid-technology .reset").show();
			}
		});
		$(".filters.technology-categories .reset").click(function () {
			var taxonomy_text = $(".filters.technology-categories")
				.find("select.custom option:first-child")
				.text();
			$(".filters.technology-categories")
				.find(".nice-select span.current")
				.text(taxonomy_text);
			$(".filters.technology-categories")
				.find(".nice-select ul.list li.option")
				.removeClass("selected");
			$(".filters.technology-categories")
				.find(".nice-select ul.list li:first-child")
				.addClass("selected");
			$("#grid-technology .grid-wrapper").find("article").show();
			$(this).hide();
		});

		/* ************************************************************ */
		/* LEGACY MESS */
		/* ************************************************************ */
		// Tab module
		$(".tabs .tab-nav a").on("click", function (e) {
			e.preventDefault();

			$(".tabs .tab-nav > li a.active").removeClass("active");
			$(".tabs .tab-content > li").height(0).removeClass("active");
			$(this).addClass("active");

			var target = this.href.split("#").pop();
			$("#" + target)
				.addClass("active")
				.height("auto");
		});

		$(".module-accordion input.hidden").on("change", function (e) {
			if ($(this).prop("type") == "radio") {
				$(".module-accordion .wrapper").height(0);
			} else {
				if ($(this).prop("checked")) {
					$(this).next().next().height("auto");
				} else {
					$(this).next().next().height(0);
				}
			}
		});

		$(".show-all").on("click", function () {
			$(this)
				.closest(".mpfy-mll-l-title")
				.find(".mpfy-mll-l-categories-hover")
				.toggleClass("showing");
		});
		//jQuery(document).ready(function(n){(new WOW).init()}),function(n){n(window).load(function(){if(!n("html").hasClass("touch")){skrollr.init({forceHeight:!1,render:function(n){}})}})}(jQuery);
	}); //End doc.ready
})(jQuery);

document.addEventListener("DOMContentLoaded", function () {
	// responsive tables
	const tables = document.querySelectorAll(".site-content table");
	if (tables) {
		tables.forEach((table) => {
			const wrapper = document.createElement("div");
			wrapper.classList.add("table-container");
			table.parentNode.insertBefore(wrapper, table);
			wrapper.appendChild(table);
		});
	}
});
